* {
  box-sizing: border-box;
  margin-bottom: 1px;
}

body {
  border-top: 13px solid #5d70e6;
  margin: 0;
  font-family: "Poppins", sans-serif;
  font-size: 1.1rem;
  overflow-x: hidden;
  padding-left: 8px;
  padding-right: 5px;
}

h1,
h2,
h3 {
  color: #5d70e6;
  margin-top: 8px;
  padding-top: 8px;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

ul li {
  list-style-type: square;
}

p {
  margin-bottom: 5px;
  padding-bottom: 5px;
}

a {
  color: black;
}

@media only screen and (min-width: 1024px) {
  .container {
    max-width: 80%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media print {
  h1,
  h2,
  h3 {
    margin-top: 12px;
    padding-top: 12px;
    margin-bottom: 12px;
    padding-bottom: 12px;
  }
  .qrcode {
    display: block !important;
    padding-top: 100px;
    margin: auto;
    width: 20%;
  }
  .qrcode p {
    margin: 0px;
  }
}

#name {
  font-size: 3.4rem;
}

.qrcode {
  display: none;
}

.grey {
  color: grey;
}

.container {
  margin: auto;
  overflow: auto;
}

.date {
  color: #696969;
  font-size: 0.9rem;
  margin-top: 15px;
}

hr {
  border-top: 1px solid lightgrey;
  margin-top: 1px;
}

.justify-content-between {
  display: flex;
  justify-content: space-between;
}

.text {
  display: inline;
}

.bold {
  font-weight: 700;
  margin-bottom: 10px;
  margin-top: 10px;
}

.tagCloud {
  display: flex;
  margin-top: 10px;
  background-color: white;
}
